import React, { useContext, useState } from "react";
import Sidebar from "../Components/Coman/Sidebar";
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import { ProjectContext } from "../ProjectContext";

export default function Academy() {
  const { videos } = useContext(ProjectContext);
  const [c_video, setc_video] = useState(null);

  return (
    <>
      <Sidebar />
      <section
        className="dashboard-section body-collapse pay step deposit-money withdraw-money"
        style={{ background: "#F7F9FD" }}
      >
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content" style={{ background: "#fff" }}>
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5>Academy</h5>
              </div>
              <section className="benefits-section">
                <div className="overlay  pb-120">
                  <div className="container">
                    <div className="top-wrapper">
                      <div className="row">
                        {c_video ?
                          <MediaPlayer title="DefiiPe Academy" autoplay src={`assets/academy/${c_video}`}>
                            <MediaProvider />
                            <DefaultVideoLayout icons={defaultLayoutIcons} />
                          </MediaPlayer>
                          : ''}
                      </div>
                      <div className="row wrapper">
                        {videos.map((ele, i) => {
                          return (
                            <div className="col-lg-2 col-md-6">
                              <div className="single-item text-center bg-des ">
                                <h5 className="text-white">Video {i + 1}</h5>
                                <button
                                  type="button"
                                  class="btn btn-warning btn-lg py-2 mt-3 "
                                  style={{ width: "70%" }}
                                  disabled={ele.video ? false : true}
                                  onClick={() => setc_video(ele.video ? ele.video : '')}
                                >
                                  {c_video === ele.video ? "Playing" : "Play"}
                                </button>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
